const template = {
    "topic": "order-status-check",
    "content": "need to check my order status, check my order",
    "workflow": {
        "startAt": "vx8tohzbxp",
        "states": {
            "vx8tohzbxp": {
                "type": "message",
                "next": "4ftp2kztid",
                "stateConfig": {
                    "type": "text",
                    "name": "greet",
                    "displayTextOptions": [
                        {
                            "displayText": "Of course, I can assist in check you order status"
                        }
                    ]
                }
            },
            "4ftp2kztid": {
                "type": "prompt",
                "next": "4ebj71k099",
                "stateConfig": {
                    "name": "email",
                    "slot": "email",
                    "displayTextOptions": [
                        {
                            "displayText": "Can you share your email ?"
                        }
                    ],
                    "type": "text"
                }
            },
            "4ebj71k099": {
                "type": "prompt",
                "next": "d5st6y6y02",
                "stateConfig": {
                    "name": "orderId",
                    "slot": "orderId",
                    "displayTextOptions": [
                        {
                            "displayText": "Which order ID do you need to check the status for?"
                        }
                    ],
                    "type": "text"
                }
            },
            "d5st6y6y02": {
                "type": "action",
                "next": "8lp4xl2nzy",
                "stateConfig": {
                    "name": "send-otp",
                    "type": "webhook",
                    "slotToAssign": "",
                    "skipNotify": true,
                    "pauseAfterExecution": false,
                    "defaultInput": "{}",
                    "webhookConfig": {
                        "url": "https://5e7roplfzvvmd6ebnt2mkawtxa0lpfas.lambda-url.us-east-1.on.aws",
                        "method": "POST",
                        "parameters": [],
                        "requestFormData": [],
                        "requestBody": "{\n    \"email\": {email},\n    \"testType\": \"order-status-check-otp\"\n}",
                        "requestType": "raw",
                        "headers": [],
                        "responseMapping": []
                    },
                    "requestMapper": {
                        "type": "inline_script",
                        "inlineScript": ""
                    },
                    "responseMapper": {
                        "type": "inline_script",
                        "inlineScript": ""
                    }
                }
            },
            "nmjy6425g2": {
                "type": "action",
                "next": "td5lrs3pn5",
                "stateConfig": {
                    "name": "order-status-get",
                    "type": "webhook",
                    "slotToAssign": "",
                    "skipNotify": true,
                    "pauseAfterExecution": false,
                    "defaultInput": "{}",
                    "webhookConfig": {
                        "url": "https://5e7roplfzvvmd6ebnt2mkawtxa0lpfas.lambda-url.us-east-1.on.aws",
                        "method": "POST",
                        "parameters": [],
                        "requestFormData": [],
                        "requestBody": "{\n    \"email\": {email},\n    \"otp\": {otp},\n    \"orderId\": {orderId},\n    \"testType\": \"order-status-check\"\n}",
                        "requestType": "raw",
                        "headers": [],
                        "responseMapping": [
                            {
                                "key": "$.status",
                                "slot": "orderStatus"
                            },
                            {
                                "key": "$.eta",
                                "slot": "orderETA"
                            }
                        ]
                    },
                    "requestMapper": {
                        "type": "inline_script",
                        "inlineScript": ""
                    },
                    "responseMapper": {
                        "type": "inline_script",
                        "inlineScript": ""
                    }
                }
            },
            "8lp4xl2nzy": {
                "type": "prompt",
                "next": "nmjy6425g2",
                "stateConfig": {
                    "name": "otp",
                    "slot": "otp",
                    "displayTextOptions": [
                        {
                            "displayText": "I have sent OTP to your email - {email}. can you type"
                        }
                    ],
                    "type": "text"
                }
            },
            "td5lrs3pn5": {
                "type": "message",
                "stateConfig": {
                    "type": "text",
                    "name": "check-order-status",
                    "displayTextOptions": [
                        {
                            "displayText": "Your order {orderId} is currently {orderStatus}. Estimated arrival: {orderETA}."
                        }
                    ]
                }
            }
        },
        "content": "need to check my order status, check my order",
        "metadata": {
            "nodes": [
                {
                    "width": 288,
                    "height": 122,
                    "id": "nmjy6425g2",
                    "type": "webhookAction",
                    "data": {
                        "id": "nmjy6425g2",
                        "type": "webhookAction",
                        "nodeIndex": 8,
                        "name": "New Step 1",
                        "metadata": {
                            "journeyId": "order-status-check",
                            "slots": []
                        },
                        "selected": false
                    },
                    "position": {
                        "x": 2370,
                        "y": 500
                    },
                    "sourcePosition": "right",
                    "targetPosition": "left",
                    "selected": false,
                    "positionAbsolute": {
                        "x": 2370,
                        "y": 500
                    },
                    "dragging": false
                },
                {
                    "width": 288,
                    "height": 122,
                    "id": "8lp4xl2nzy",
                    "type": "questionPrompt",
                    "data": {
                        "id": "8lp4xl2nzy",
                        "type": "questionPrompt",
                        "nodeIndex": 7,
                        "name": "New Step 1",
                        "metadata": {
                            "journeyId": "order-status-check",
                            "slots": []
                        },
                        "selected": false
                    },
                    "position": {
                        "x": 1850,
                        "y": 450
                    },
                    "sourcePosition": "right",
                    "targetPosition": "left",
                    "selected": false,
                    "dragging": false,
                    "positionAbsolute": {
                        "x": 1850,
                        "y": 450
                    }
                },
                {
                    "width": 288,
                    "height": 122,
                    "id": "d5st6y6y02",
                    "type": "webhookAction",
                    "data": {
                        "id": "d5st6y6y02",
                        "type": "webhookAction",
                        "nodeIndex": 7,
                        "name": "New Step 1",
                        "metadata": {
                            "journeyId": "order-status-check",
                            "slots": []
                        },
                        "selected": false
                    },
                    "position": {
                        "x": 1400,
                        "y": 380
                    },
                    "sourcePosition": "right",
                    "targetPosition": "left",
                    "selected": false,
                    "dragging": false,
                    "positionAbsolute": {
                        "x": 1400,
                        "y": 380
                    }
                },
                {
                    "width": 288,
                    "height": 122,
                    "id": "td5lrs3pn5",
                    "type": "messagePrompt",
                    "data": {
                        "id": "td5lrs3pn5",
                        "type": "messagePrompt",
                        "nodeIndex": 6,
                        "name": "New Step 1",
                        "metadata": {},
                        "selected": true
                    },
                    "position": {
                        "x": 3010,
                        "y": 440
                    },
                    "sourcePosition": "right",
                    "targetPosition": "left",
                    "selected": true,
                    "dragging": false,
                    "positionAbsolute": {
                        "x": 3010,
                        "y": 440
                    }
                },
                {
                    "width": 288,
                    "height": 122,
                    "id": "4ebj71k099",
                    "type": "questionPrompt",
                    "data": {
                        "id": "4ebj71k099",
                        "type": "questionPrompt",
                        "nodeIndex": 3,
                        "name": "New Step 1",
                        "metadata": {},
                        "selected": false
                    },
                    "position": {
                        "x": 950,
                        "y": 240
                    },
                    "sourcePosition": "right",
                    "targetPosition": "left",
                    "selected": false,
                    "positionAbsolute": {
                        "x": 950,
                        "y": 240
                    },
                    "dragging": false
                },
                {
                    "width": 288,
                    "height": 122,
                    "id": "4ftp2kztid",
                    "type": "questionPrompt",
                    "data": {
                        "id": "4ftp2kztid",
                        "type": "questionPrompt",
                        "nodeIndex": 2,
                        "name": "New Step 1",
                        "metadata": {},
                        "selected": false
                    },
                    "position": {
                        "x": 530,
                        "y": 130
                    },
                    "sourcePosition": "right",
                    "targetPosition": "left",
                    "selected": false,
                    "positionAbsolute": {
                        "x": 530,
                        "y": 130
                    },
                    "dragging": false
                },
                {
                    "width": 288,
                    "height": 122,
                    "id": "vx8tohzbxp",
                    "type": "messagePrompt",
                    "data": {
                        "id": "vx8tohzbxp",
                        "type": "messagePrompt",
                        "nodeIndex": 1,
                        "name": "New Step 1",
                        "metadata": {},
                        "selected": false
                    },
                    "position": {
                        "x": 160,
                        "y": 80
                    },
                    "sourcePosition": "right",
                    "targetPosition": "left",
                    "selected": false,
                    "dragging": false,
                    "positionAbsolute": {
                        "x": 160,
                        "y": 80
                    }
                }
            ],
            "edges": [
                {
                    "source": "vx8tohzbxp",
                    "sourceHandle": "out",
                    "target": "4ftp2kztid",
                    "targetHandle": "in",
                    "data": {},
                    "type": "customEdge",
                    "markerEnd": {
                        "type": "arrowclosed"
                    },
                    "id": "reactflow__edge-vx8tohzbxpout-4ftp2kztidin"
                },
                {
                    "source": "4ftp2kztid",
                    "sourceHandle": "out",
                    "target": "4ebj71k099",
                    "targetHandle": "in",
                    "data": {},
                    "type": "customEdge",
                    "markerEnd": {
                        "type": "arrowclosed"
                    },
                    "id": "reactflow__edge-4ftp2kztidout-4ebj71k099in"
                },
                {
                    "source": "4ebj71k099",
                    "sourceHandle": "out",
                    "target": "d5st6y6y02",
                    "targetHandle": "in",
                    "data": {},
                    "type": "customEdge",
                    "markerEnd": {
                        "type": "arrowclosed"
                    },
                    "id": "reactflow__edge-4ebj71k099out-d5st6y6y02in"
                },
                {
                    "source": "d5st6y6y02",
                    "sourceHandle": "out",
                    "target": "8lp4xl2nzy",
                    "targetHandle": "in",
                    "data": {},
                    "type": "customEdge",
                    "markerEnd": {
                        "type": "arrowclosed"
                    },
                    "id": "reactflow__edge-d5st6y6y02out-8lp4xl2nzyin"
                },
                {
                    "source": "nmjy6425g2",
                    "sourceHandle": "out",
                    "target": "td5lrs3pn5",
                    "targetHandle": "in",
                    "data": {},
                    "type": "customEdge",
                    "markerEnd": {
                        "type": "arrowclosed"
                    },
                    "id": "reactflow__edge-nmjy6425g2out-td5lrs3pn5in"
                },
                {
                    "source": "8lp4xl2nzy",
                    "sourceHandle": "out",
                    "target": "nmjy6425g2",
                    "targetHandle": "in",
                    "data": {},
                    "type": "customEdge",
                    "markerEnd": {
                        "type": "arrowclosed"
                    },
                    "id": "reactflow__edge-8lp4xl2nzyout-nmjy6425g2in"
                }
            ],
            "journeyStmStore": {
                "vx8tohzbxp": {
                    "type": "message",
                    "stateConfig": {
                        "type": "text",
                        "name": "greet",
                        "displayTextOptions": [
                            {
                                "displayText": "Of course, I can assist in check you order status"
                            }
                        ]
                    }
                },
                "4ftp2kztid": {
                    "type": "prompt",
                    "stateConfig": {
                        "name": "email",
                        "slot": "email",
                        "displayTextOptions": [
                            {
                                "displayText": "Can you share your email ?"
                            }
                        ],
                        "type": "text"
                    }
                },
                "4ebj71k099": {
                    "type": "prompt",
                    "stateConfig": {
                        "name": "orderId",
                        "slot": "orderId",
                        "displayTextOptions": [
                            {
                                "displayText": "Which order ID do you need to check the status for?"
                            }
                        ],
                        "type": "text"
                    }
                },
                "td5lrs3pn5": {
                    "type": "message",
                    "stateConfig": {
                        "type": "text",
                        "name": "check-order-status",
                        "displayTextOptions": [
                            {
                                "displayText": "Your order {orderId} is currently {orderStatus}. Estimated arrival: {orderETA}."
                            }
                        ]
                    }
                },
                "d5st6y6y02": {
                    "type": "action",
                    "stateConfig": {
                        "name": "send-otp",
                        "type": "webhook",
                        "slotToAssign": "",
                        "skipNotify": true,
                        "pauseAfterExecution": false,
                        "defaultInput": "{}",
                        "webhookConfig": {
                            "url": "https://5e7roplfzvvmd6ebnt2mkawtxa0lpfas.lambda-url.us-east-1.on.aws",
                            "method": "POST",
                            "parameters": [],
                            "requestFormData": [],
                            "requestBody": "{\n    \"email\": {email},\n    \"testType\": \"order-status-check-otp\"\n}",
                            "requestType": "raw",
                            "headers": [],
                            "responseMapping": []
                        },
                        "requestMapper": {
                            "type": "inline_script",
                            "inlineScript": ""
                        },
                        "responseMapper": {
                            "type": "inline_script",
                            "inlineScript": ""
                        }
                    }
                },
                "8lp4xl2nzy": {
                    "type": "prompt",
                    "stateConfig": {
                        "name": "otp",
                        "slot": "otp",
                        "displayTextOptions": [
                            {
                                "displayText": "I have sent OTP to your email - {email}. can you type"
                            }
                        ],
                        "type": "text"
                    }
                },
                "n47mv5anht": {
                    "type": "message",
                    "stateConfig": {
                        "type": "text",
                        "name": "otp",
                        "displayTextOptions": [
                            {
                                "displayText": "I sent an OTP to your email - {email}. Can you enter it?"
                            }
                        ]
                    }
                },
                "nmjy6425g2": {
                    "type": "action",
                    "stateConfig": {
                        "name": "order-status-get",
                        "type": "webhook",
                        "slotToAssign": "",
                        "skipNotify": true,
                        "pauseAfterExecution": false,
                        "defaultInput": "{}",
                        "webhookConfig": {
                            "url": "https://5e7roplfzvvmd6ebnt2mkawtxa0lpfas.lambda-url.us-east-1.on.aws",
                            "method": "POST",
                            "parameters": [],
                            "requestFormData": [],
                            "requestBody": "{\n    \"email\": {email},\n    \"otp\": {otp},\n    \"orderId\": {orderId},\n    \"testType\": \"order-status-check\"\n}",
                            "requestType": "raw",
                            "headers": [],
                            "responseMapping": [
                                {
                                    "key": "$.status",
                                    "slot": "orderStatus"
                                },
                                {
                                    "key": "$.eta",
                                    "slot": "orderETA"
                                }
                            ]
                        },
                        "requestMapper": {
                            "type": "inline_script",
                            "inlineScript": ""
                        },
                        "responseMapper": {
                            "type": "inline_script",
                            "inlineScript": ""
                        }
                    }
                }
            }
        },
        "modifiedAt": "2024-09-12T20:23:17Z"
    },
    "metadata": {
        "nodes": [
            {
                "width": 288,
                "height": 122,
                "id": "nmjy6425g2",
                "type": "webhookAction",
                "data": {
                    "id": "nmjy6425g2",
                    "type": "webhookAction",
                    "nodeIndex": 8,
                    "name": "New Step 1",
                    "metadata": {
                        "journeyId": "order-status-check",
                        "slots": []
                    },
                    "selected": false
                },
                "position": {
                    "x": 2370,
                    "y": 500
                },
                "sourcePosition": "right",
                "targetPosition": "left",
                "selected": false,
                "positionAbsolute": {
                    "x": 2370,
                    "y": 500
                },
                "dragging": false
            },
            {
                "width": 288,
                "height": 122,
                "id": "8lp4xl2nzy",
                "type": "questionPrompt",
                "data": {
                    "id": "8lp4xl2nzy",
                    "type": "questionPrompt",
                    "nodeIndex": 7,
                    "name": "New Step 1",
                    "metadata": {
                        "journeyId": "order-status-check",
                        "slots": []
                    },
                    "selected": false
                },
                "position": {
                    "x": 1850,
                    "y": 450
                },
                "sourcePosition": "right",
                "targetPosition": "left",
                "selected": false,
                "dragging": false,
                "positionAbsolute": {
                    "x": 1850,
                    "y": 450
                }
            },
            {
                "width": 288,
                "height": 122,
                "id": "d5st6y6y02",
                "type": "webhookAction",
                "data": {
                    "id": "d5st6y6y02",
                    "type": "webhookAction",
                    "nodeIndex": 7,
                    "name": "New Step 1",
                    "metadata": {
                        "journeyId": "order-status-check",
                        "slots": []
                    },
                    "selected": false
                },
                "position": {
                    "x": 1400,
                    "y": 380
                },
                "sourcePosition": "right",
                "targetPosition": "left",
                "selected": false,
                "dragging": false,
                "positionAbsolute": {
                    "x": 1400,
                    "y": 380
                }
            },
            {
                "width": 288,
                "height": 122,
                "id": "td5lrs3pn5",
                "type": "messagePrompt",
                "data": {
                    "id": "td5lrs3pn5",
                    "type": "messagePrompt",
                    "nodeIndex": 6,
                    "name": "New Step 1",
                    "metadata": {},
                    "selected": true
                },
                "position": {
                    "x": 3010,
                    "y": 440
                },
                "sourcePosition": "right",
                "targetPosition": "left",
                "selected": true,
                "dragging": false,
                "positionAbsolute": {
                    "x": 3010,
                    "y": 440
                }
            },
            {
                "width": 288,
                "height": 122,
                "id": "4ebj71k099",
                "type": "questionPrompt",
                "data": {
                    "id": "4ebj71k099",
                    "type": "questionPrompt",
                    "nodeIndex": 3,
                    "name": "New Step 1",
                    "metadata": {},
                    "selected": false
                },
                "position": {
                    "x": 950,
                    "y": 240
                },
                "sourcePosition": "right",
                "targetPosition": "left",
                "selected": false,
                "positionAbsolute": {
                    "x": 950,
                    "y": 240
                },
                "dragging": false
            },
            {
                "width": 288,
                "height": 122,
                "id": "4ftp2kztid",
                "type": "questionPrompt",
                "data": {
                    "id": "4ftp2kztid",
                    "type": "questionPrompt",
                    "nodeIndex": 2,
                    "name": "New Step 1",
                    "metadata": {},
                    "selected": false
                },
                "position": {
                    "x": 530,
                    "y": 130
                },
                "sourcePosition": "right",
                "targetPosition": "left",
                "selected": false,
                "positionAbsolute": {
                    "x": 530,
                    "y": 130
                },
                "dragging": false
            },
            {
                "width": 288,
                "height": 122,
                "id": "vx8tohzbxp",
                "type": "messagePrompt",
                "data": {
                    "id": "vx8tohzbxp",
                    "type": "messagePrompt",
                    "nodeIndex": 1,
                    "name": "New Step 1",
                    "metadata": {},
                    "selected": false
                },
                "position": {
                    "x": 160,
                    "y": 80
                },
                "sourcePosition": "right",
                "targetPosition": "left",
                "selected": false,
                "dragging": false,
                "positionAbsolute": {
                    "x": 160,
                    "y": 80
                }
            }
        ],
        "edges": [
            {
                "source": "vx8tohzbxp",
                "sourceHandle": "out",
                "target": "4ftp2kztid",
                "targetHandle": "in",
                "data": {},
                "type": "customEdge",
                "markerEnd": {
                    "type": "arrowclosed"
                },
                "id": "reactflow__edge-vx8tohzbxpout-4ftp2kztidin"
            },
            {
                "source": "4ftp2kztid",
                "sourceHandle": "out",
                "target": "4ebj71k099",
                "targetHandle": "in",
                "data": {},
                "type": "customEdge",
                "markerEnd": {
                    "type": "arrowclosed"
                },
                "id": "reactflow__edge-4ftp2kztidout-4ebj71k099in"
            },
            {
                "source": "4ebj71k099",
                "sourceHandle": "out",
                "target": "d5st6y6y02",
                "targetHandle": "in",
                "data": {},
                "type": "customEdge",
                "markerEnd": {
                    "type": "arrowclosed"
                },
                "id": "reactflow__edge-4ebj71k099out-d5st6y6y02in"
            },
            {
                "source": "d5st6y6y02",
                "sourceHandle": "out",
                "target": "8lp4xl2nzy",
                "targetHandle": "in",
                "data": {},
                "type": "customEdge",
                "markerEnd": {
                    "type": "arrowclosed"
                },
                "id": "reactflow__edge-d5st6y6y02out-8lp4xl2nzyin"
            },
            {
                "source": "nmjy6425g2",
                "sourceHandle": "out",
                "target": "td5lrs3pn5",
                "targetHandle": "in",
                "data": {},
                "type": "customEdge",
                "markerEnd": {
                    "type": "arrowclosed"
                },
                "id": "reactflow__edge-nmjy6425g2out-td5lrs3pn5in"
            },
            {
                "source": "8lp4xl2nzy",
                "sourceHandle": "out",
                "target": "nmjy6425g2",
                "targetHandle": "in",
                "data": {},
                "type": "customEdge",
                "markerEnd": {
                    "type": "arrowclosed"
                },
                "id": "reactflow__edge-8lp4xl2nzyout-nmjy6425g2in"
            }
        ],
        "journeyStmStore": {
            "vx8tohzbxp": {
                "type": "message",
                "stateConfig": {
                    "type": "text",
                    "name": "greet",
                    "displayTextOptions": [
                        {
                            "displayText": "Of course, I can assist in check you order status"
                        }
                    ]
                }
            },
            "4ftp2kztid": {
                "type": "prompt",
                "stateConfig": {
                    "name": "email",
                    "slot": "email",
                    "displayTextOptions": [
                        {
                            "displayText": "Can you share your email ?"
                        }
                    ],
                    "type": "text"
                }
            },
            "4ebj71k099": {
                "type": "prompt",
                "stateConfig": {
                    "name": "orderId",
                    "slot": "orderId",
                    "displayTextOptions": [
                        {
                            "displayText": "Which order ID do you need to check the status for?"
                        }
                    ],
                    "type": "text"
                }
            },
            "td5lrs3pn5": {
                "type": "message",
                "stateConfig": {
                    "type": "text",
                    "name": "check-order-status",
                    "displayTextOptions": [
                        {
                            "displayText": "Your order {orderId} is currently {orderStatus}. Estimated arrival: {orderETA}."
                        }
                    ]
                }
            },
            "d5st6y6y02": {
                "type": "action",
                "stateConfig": {
                    "name": "send-otp",
                    "type": "webhook",
                    "slotToAssign": "",
                    "skipNotify": true,
                    "pauseAfterExecution": false,
                    "defaultInput": "{}",
                    "webhookConfig": {
                        "url": "https://5e7roplfzvvmd6ebnt2mkawtxa0lpfas.lambda-url.us-east-1.on.aws",
                        "method": "POST",
                        "parameters": [],
                        "requestFormData": [],
                        "requestBody": "{\n    \"email\": {email},\n    \"testType\": \"order-status-check-otp\"\n}",
                        "requestType": "raw",
                        "headers": [],
                        "responseMapping": []
                    },
                    "requestMapper": {
                        "type": "inline_script",
                        "inlineScript": ""
                    },
                    "responseMapper": {
                        "type": "inline_script",
                        "inlineScript": ""
                    }
                }
            },
            "8lp4xl2nzy": {
                "type": "prompt",
                "stateConfig": {
                    "name": "otp",
                    "slot": "otp",
                    "displayTextOptions": [
                        {
                            "displayText": "I have sent OTP to your email - {email}. can you type"
                        }
                    ],
                    "type": "text"
                }
            },
            "n47mv5anht": {
                "type": "message",
                "stateConfig": {
                    "type": "text",
                    "name": "otp",
                    "displayTextOptions": [
                        {
                            "displayText": "I sent an OTP to your email - {email}. Can you enter it?"
                        }
                    ]
                }
            },
            "nmjy6425g2": {
                "type": "action",
                "stateConfig": {
                    "name": "order-status-get",
                    "type": "webhook",
                    "slotToAssign": "",
                    "skipNotify": true,
                    "pauseAfterExecution": false,
                    "defaultInput": "{}",
                    "webhookConfig": {
                        "url": "https://5e7roplfzvvmd6ebnt2mkawtxa0lpfas.lambda-url.us-east-1.on.aws",
                        "method": "POST",
                        "parameters": [],
                        "requestFormData": [],
                        "requestBody": "{\n    \"email\": {email},\n    \"otp\": {otp},\n    \"orderId\": {orderId},\n    \"testType\": \"order-status-check\"\n}",
                        "requestType": "raw",
                        "headers": [],
                        "responseMapping": [
                            {
                                "key": "$.status",
                                "slot": "orderStatus"
                            },
                            {
                                "key": "$.eta",
                                "slot": "orderETA"
                            }
                        ]
                    },
                    "requestMapper": {
                        "type": "inline_script",
                        "inlineScript": ""
                    },
                    "responseMapper": {
                        "type": "inline_script",
                        "inlineScript": ""
                    }
                }
            }
        }
    }
}

export default template