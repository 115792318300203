import greetTemplate from "./greet";
import appointmentBookTemplate from "./appointment-book";
import orderStatusCheckTemplate from "./order-status-check";

const templateMap = {
    'greet': greetTemplate,
    'appointment-book': appointmentBookTemplate,
    'order-status-check': orderStatusCheckTemplate
}

export default templateMap